import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import categoriesSlice from "./general/categoriesSlice";
import reduxResetSlice from "./general/reduxResetSlice";
import uploadFileSlice from "./general/uploadFileSlice";

const combinedReducer = combineReducers({
  reduxReset: reduxResetSlice,
  categories: categoriesSlice,
  uploadFile: uploadFileSlice,
});
const rootReducer = (state, action) => {
  if (action.type === "redux_reset/setItemList") {
    state = {
      ...state,
      myProfile: {},
      users: {},
    };
  }
  return combinedReducer(state, action);
};
export default configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware()],
});
