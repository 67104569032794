import { createSlice } from "@reduxjs/toolkit";

const reduxResetSlice = createSlice({
  name: "redux_reset",
  initialState: {
    reset: null,
  },
  reducers: {
    setItemList: (state, { payload }) => {
      state.reset = payload;
    },
  },
});

export const { setItemList } = reduxResetSlice.actions; // functions dışarıya aktarılması

export default reduxResetSlice.reducer;

export function getReduxReset() {
  return async (dispatch) => {
    dispatch(setItemList(true));
  };
}
