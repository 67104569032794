import { createSlice } from "@reduxjs/toolkit";

const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    selectedCategoriesList: [],
    reloadStatus: false,
    selectedNodeId: null,
  },
  reducers: {
    setSelectedCategories: (state, { payload }) => {
      state.selectedCategoriesList = [...state.selectedCategoriesList, payload];
    },
    clearSelectedCategories: (state) => {
      state.selectedCategoriesList = [];
    },
    setReloadStatus: (state) => {
      state.reloadStatus = !state.reloadStatus;
    },
    setSelectedNodeId: (state, { payload }) => {
      state.selectedNodeId = payload;
    },
  },
});

export const categoriesData = (state) => state.categories;

export const {
  setSelectedCategories,
  clearSelectedCategories,
  setReloadStatus,
  setSelectedNodeId,
} = categoriesSlice.actions;

export default categoriesSlice.reducer;
