import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const CancelToken = axios.CancelToken;

const initialState = {
  uploadFileList: [],
  numberOfFilesUpload: 0,
  uploading: false,
  allFilesUploaded: false,
  resourceInput: null,
  uploadAbortFileList: [],
  uploadStatus: {
    complete: false,
    progress: 0,
    uid: null,
  },
};

const fileUploadSlice = createSlice({
  name: "uploadFile",
  initialState: initialState,
  reducers: {
    setFileList: (state, { payload }) => {
      state.uploadFileList.push(payload);
    },
    setUploadStatus: (state, { payload }) => {
      state.uploadStatus = payload;
    },
    setLoading: (state, { payload }) => {
      state.uploading = payload;
    },
    setNumberOfFilesUpload: (state, { payload }) => {
      state.numberOfFilesUpload += payload;
    },
    setAllFilesUploaded: (state, { payload }) => {
      state.allFilesUploaded = payload;
    },
    setResourceInput: (state, { payload }) => {
      state.resourceInput = payload;
    },
    setUploadAbortFile: (state, { payload }) => {
      state.uploadAbortFileList.push(payload);
    },

    resetState: (state) => initialState,
  },
});

export const {
  setFileList,
  setUploadStatus,
  setLoading,
  setNumberOfFilesUpload,
  setAllFilesUploaded,
  resetState,
  setResourceInput,
  setUploadAbortFile,
} = fileUploadSlice.actions;

export const putFile2Cloud = (file, item, setUploadAbortList) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    axios
      .put(item?.put_url, file, {
        headers: { "content-type": file.type },
        cancelToken: new CancelToken(function executor(c) {
          setUploadAbortList((prev) => [...prev, { uid: file.uid, func: c }]);
        }),
        onUploadProgress: (progress) => {
          const uploadRatio = Math.round((progress.loaded * 100) / progress.total);
          dispatch(
            setUploadStatus({
              complete: false,
              progress: uploadRatio,
              uid: file.uid,
              aborted: false,
            })
          );
          if (progress.loaded === progress.total) {
            dispatch(setNumberOfFilesUpload(1));
            dispatch(
              setUploadStatus({
                complete: true,
                progress: uploadRatio,
                uid: file.uid,
                aborted: false,
              })
            );
          }
        },
      })
      .then((resp) => {
        const input = { key: item?.key, filename: file?.name, url: item?.get_url };
        dispatch(setFileList({ uid: file.uid }));
        dispatch(setResourceInput(input));
        return input;
      })
      .catch((err) => {
        if (err.message === "upload-abort") {
          dispatch(setNumberOfFilesUpload(1));
          dispatch(setUploadAbortFile({ uid: file.uid }));

          dispatch(
            setUploadStatus({
              complete: true,
              progress: 0,
              uid: file.uid,
              aborted: true,
            })
          );
        }
      });
  } catch (err) {
    console.log("error : ", err);
  }
};

export const fileList = (state) => state.uploadFile?.uploadFileList;
export const uploadStatus = (state) => state.uploadFile?.uploadStatus;
export const uploading = (state) => state.uploadFile?.uploading;
export const resourceInput = (state) => state.uploadFile?.resourceInput;
export const allFilesUploaded = (state) => state.uploadFile?.allFilesUploaded;
export const uploadAbortFileList = (state) => state.uploadFile?.uploadAbortFileList;

export const numberOfFilesUpload = (state) => state.uploadFile?.numberOfFilesUpload;

export default fileUploadSlice.reducer;
