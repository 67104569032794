import { lazy } from "react";

//private
const RouterPrivate = lazy(() => import("./routerPrivate"));
const RouterAdmin = lazy(() => import("./routerAdmin"));

// membership
const RouterMembership = lazy(() => import("./routerMembership"));
const Login = lazy(() => import("../Views/Auth/Login"));
const PasswordCreate = lazy(() => import("../Views/Auth/PasswordCreate"));
const Registration = lazy(() => import("../Views/Auth/Registration"));
const ForgotMyPassword = lazy(() => import("../Views/Auth/ForgotMyPassword"));
const Confirmation = lazy(() => import("../Views/Auth/Confirmation"));
const Lobby = lazy(() => import("../Views/Pages/Lobby"));

export {
  RouterPrivate,
  RouterAdmin,
  RouterMembership,
  // membership
  Registration,
  Login,
  PasswordCreate,
  ForgotMyPassword,
  Confirmation,
  Lobby,
};
