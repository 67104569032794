import moment from "moment";

export const getLocalstorage = (value) => {
  const getValue = localStorage.getItem(value);
  return JSON.parse(getValue || null);
};

export const setLocalstorage = (value, data) => {
  localStorage.setItem(value, JSON.stringify(data));
};

export const removeLocalstorage = (value) => {
  localStorage.removeItem(value);
};

export const getCurrentCompany = () => getLocalstorage("currentCompany");
export const setCurrentCompany = (meCompany) => setLocalstorage("currentCompany", meCompany);

export const setMe = (meData) => setLocalstorage("me", meData);
export const getMe = () => getLocalstorage("me");

export const getToken = () => getLocalstorage("token");
export const setToken = (token) => setLocalstorage("token", token);
export const clearLocalStore = () => window.localStorage.clear();

export const getCompanyType = () => {
  return getCurrentCompany?.type_name;
};

export const getTablePageData = () => {
  const _tablePage = getLocalstorage("tablePage");
  // return dict if not set
  return _tablePage ? _tablePage : {};
};

export const setTablePageData = (_tablePage) => setLocalstorage("tablePage", _tablePage);

const _dictKeyForFilter = (filterType, resourceId) => {
  return filterType + "-" + (resourceId ? resourceId : "-");
};
export const setTablePage = (filterType, resourceId, pageData) => {
  const _tablePageData = getTablePageData();
  const _dictKey = _dictKeyForFilter(filterType, resourceId);
  _tablePageData[_dictKey] = pageData;
  setTablePageData(_tablePageData);
};

export const getTablePage = (filterType, resourceId) => {
  const _tablePageData = getTablePageData();

  const _dictKey = _dictKeyForFilter(filterType, resourceId);
  const _foundPage = _tablePageData[_dictKey];
  if (!_foundPage) {
    return;
  }

  if (_foundPage?.filters) {
    for (let _filter of _foundPage?.filters) {
      if (
        // all date values should be here
        ["created_at", "updated_at", "target_date", "completion_date", "installation_date"].includes(_filter.field_name)
      ) {
        // convert str into moment obj
        if (_filter.value?.min) {
          _filter.value.min = moment(_filter.value.min);
        }
        if (_filter.value?.max) {
          _filter.value.max = moment(_filter.value.max);
        }
      }
    }
  } else {
    _foundPage.filters = [];
  }

  return _foundPage;
};

export const getFileBrowserConfig = () => {
  const _fbc = getLocalstorage("fileBrowserConfig");
  // return dict if not set
  return _fbc ? _fbc : {};
};

export const setFileBrowserConfig = (key, value) => {
  const _fbc = getFileBrowserConfig();
  _fbc[key] = value;
  setLocalstorage("fileBrowserConfig", _fbc);
};
